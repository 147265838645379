import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import BannerLanding from '../components/BannerLanding'
import { isLoggedIn } from "../services/auth"
import Login from './login'

import pic01 from '../assets/images/instructions-citrus-01.jpg'
import pic02 from '../assets/images/instructions-citrus-02.png'

const Citrus = (props) => {
    if (!isLoggedIn()) {
        return <Login />
    }
    return (
        <Layout>
            <Helmet>
                <title>Citrus - Moonlight</title>
                <meta name="description" content="Pomegranates" />
            </Helmet>

            <BannerLanding
                bannerStyle="style4"
                headerText="Navel Oranges, Lemons, and Mandarins"
                descriptionText="Sweet & Juicy, Tangy, And Easy-To-Peel"
            />

            <div id="main">
                <section id="one">
                    <div className="inner">
                        <header className="major">
                            <h2>Packed With Vitamin C And Other Anti-Oxidants</h2>
                        </header>
                        <p>California is known worldwide for its juicy and delicious citrus crop, nearly all of which come from the Central Valley. Moonlight citrus groves are located in the foothills of the Sierra Nevada—the perfect growing region thanks to its ideal soil and climate. We grow sweet and juicy navel oranges, tangy and versatile lemons, and snackable, easy-to-peel mandarins. Moonlight citrus isn’t just good, it’s good for you—packed with vitamin C and other anti-oxidants.</p>
                        <p>Moonlight citrus is at its ripest September through March.</p>
                    </div>
                </section>
                <section id="two" className="spotlights">
                    <section>
                        <div className="image">
                            <img src={pic02} alt="" />
                        </div>
                        <div className="content">
                            <div className="inner">
                                <header className="major">
                                    <h3>Cuties Now Come From Moonlight Orchards!</h3>
                                </header>
                                <p>Moonlight is pleased to announce a new partnership with everyone’s favorite snack fruit, Cuties! Many of those juicy little super sweet clementines you know and love will now come directly from our Central California orchards. Moonlight and Cuties, two names synonymous with freshness and quality, now together!</p>
                                <h5>About Cuties</h5>
                                <p>A “Cuties® Standard” exists within our extensive operation to ensure that superior quality goes into each and every box we pack. Each piece of fruit goes through a rigorous inspection to ensure a good eating quality, high sugar levels and minimal seed content, to be sure it satisfies the grade requirements so that it can be packed under the Cuties® brand. So rest assured you will enjoy an excellent tasting piece of fruit every time!</p>
                            </div>
                        </div>
                    </section>
                    <section>
                        <div className="image">
                            <img src={pic01} alt="" />
                        </div>
                        <div className="content">
                            <div className="inner">
                                <header className="major">
                                    <h3>CITRUS STORAGE AND HANDLING TIPS</h3>
                                </header>
                                <ul>
                                    <li>
                                        <p>Citrus will last much longer under refrigeration and can be stored in the refrigerator crisper drawer for up to 2 weeks. Temperatures from 35 degrees Fahrenheit to 50 degrees Fahrenheit are best.</p>
                                    </li>
                                    <li>
                                        <p>Citrus can be stored unrefrigerated in a cool, dry place for approximately four to five days if kept out of bright sunlight.</p>
                                    </li>
                                    <li>
                                        <p>Do not wash citrus fruits prior to storing them. Residual moisture can result in spoilage or mold growth.</p>
                                    </li>
                                    <li>
                                        <p>Plastic bags or film-wrapped trays should not be used for storing citrus. When airtight, drops of moisture may form between the film and the citrus peel, promoting mold growth.</p>
                                    </li>
                                    <li>
                                        <p>Juiced citrus can be poured into a plastic container and then placed in the freezer.</p>
                                    </li>
                                    <li>
                                        <p>Try pouring fresh squeezed lemon juice in ice cube trays. Once frozen you can store the cubes in zipper freezer bags for later use.</p>
                                    </li>
                                </ul>
                                <ul className="actions">
                                    <li><Link to="/#citrus" className="button">Back</Link></li>
                                </ul>
                            </div>
                        </div>
                    </section>
                </section>
            </div>

        </Layout>
    )
}

export default Citrus